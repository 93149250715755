@import "~bootstrap/scss/bootstrap.scss";
$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';
@import '~ngx-toastr/toastr-bs4-alert';
@import '~@formio/premium/dist/premium.css';
@import '~@progress/kendo-theme-bootstrap/dist/all.css';
.navbar-logo {
  height: 2em;
}

.table-responsive[ref=component] .row {
  margin-left: 0;
  margin-right: 0;
}
